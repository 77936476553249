<template>
  <div class="policy-wrapper">
    <div class="policy-title">Privacy Policy</div>
    <p>
      Consent to the collection and processing of personal data and the message about
      Rights according to the Law of Ukraine "On Protection of Personal Data" from
      06/01/2010, № 2297-VI
    </p>
    <p>
      Client (hereinafter - user) undertakes to provide a project "BibleAwr" (hereinafter
      - the owner) True, accurate and complete information about themselves on the issues
      offered in the registration form and maintain this information up to date need. If
      the user provides incorrect information or the owner has serious reason to believe
      that The information provided by the Client is incorrect, incomplete or inaccurate,
      The owner has the right to suspend or cancel registration or Fully refuse to the
      user in the further placement of the order.
    </p>
    <p>
      The user confirms that, acting on his will and in their interests, gives consent to
      the processing of its personal data, Provided by the owner, with benefits for its
      identification.
    </p>
    <p>
      Personal user data provided by the owner when its registration in accordance with
      the current legislation on Protection of personal data, not to be transferred to
      third parties without written permission of the user, with the exception of cases,
      right stipulated by the current legislation, or on the basis of court decisions of
      the country staying a user.
    </p>
    <p><b>The owner uses personal information only:</b></p>
    <p>
      to register a user on the site;<br />To fulfill its obligations to the user and his
      identification;<br />to evaluate and analyze the site;<br />To determine the
      possibility of user participation in promotions, held by the owner.
    </p>
    <p>The owner can send a message to the user. Advertising and informational nature.</p>
    <p>
      Noting the item on the registration page of this website "I I agree with the
      collection and use of my personal data, " The user confirms that notified and agrees
      with the following:
    </p>
    <p>
      When using the Internet site, the owner collects and processing personal data of the
      user associated with identification, authentication, authorization, restoration
      Password, sending information materials on subscription the user, answers to
      requests and user letters, Leaving reviews about the owner's services received, for
      registration, receiving promotional and special offers, information about
      promotions, other information about the activity of the owner, among which The
      following personal data may be:
    </p>
    <p>surname, name, patronymic, telephone, email address;</p>
    <p>
      Personal data provided by the user when filling registration forms on the website,
      as well as in the process Website use:
    </p>
    <p><b>Cookies files.</b></p>
    <p>
      Under the processing of personal data means a collection, registration,
      accumulation, storage, adaptation, refinement (update, change), use, distribution,
      (including transfer Third Parties) Defend, Destruction and other actions
      (operations) with personal data.
    </p>
    <p>
      The user is obliged to change its personal data. Provide the owner as soon as
      possible information.
    </p>
    <p>
      Owner including user data to the database of personal data with The moment the user
      first provides (sends) your personal data holder by filling Registration form on the
      owner's website.
    </p>
    <p>
      The owner uses impersonal data for advertising targeting and / or informational
      materials by age, sex, other data; for statistical research; in other ways.
    </p>
    <p>
      The owner of the database does not transmit personal data to third parties. without
      prior consent of the user. Database owner does not receive the agreement of the
      subject of personal data on the transfer of it personal data in cases provided for
      in the current The legislation of Ukraine.
    </p>
    <p>
      When used by the user, the website can There are third party Internet resources
      codes as a result What such third parties receive data denoted in subparagraphs
      7.2.3. - 7.2.5 of paragraph 7.2. This consent. Such Internet resources of third
      parties are:
    </p>
    <p>
      Systems for collecting statistics of visits of the website (for example, Google
      Analytics and others);
    </p>
    <p><b>Other resources;</b></p>
    <p>
      The user has all the rights to protect his personal data, stipulated by the current
      legislation of Ukraine in particular, the law of Ukraine "On Protection of Personal
      Data", and exactly:
    </p>
    <p>
      know about the location of the database containing his personal data, its
      appointment and name, location of its owner or manager;
    </p>
    <p>
      receive information about the conditions for providing access to Personal data, in
      particular, information about third parties, who are transferred to his personal
      data contained in the database personal data;
    </p>
    <p>
      receive no later than 30 calendar days from the day receiving the request, except
      for the cases provided for by law, information about whether its personal data is
      stored in the corresponding database of personal data, as well as receive The
      content of its personal data that is stored.
    </p>
    <p>
      make a motivated change of change or destruction of their personal data by any owner
      and manager of this bases if this data is processed illegally or not reliable;
    </p>
    <p>
      to protect your personal data from illegal processing and random loss, destruction,
      damage due to intentional concealment, failure to provide or untimely provision, as
      well as on protection against the provision of information that is not reliable or
      disruption, dignity and business reputation individual;
    </p>
    <p>
      contact the protection of their personal data rights in state authorities, local
      governments, whose authority refers to the implementation of personal protection
      data;
    </p>
    <p>
      Apply remedies in case of violation Legislation on the protection of personal data.
    </p>
    <p>on protection against an automated solution that has for it Legal consequences.</p>
    <p>
      The owner accepts all measures envisaged by law for Protection of personal data of
      the user, in particular processing data is carried out on the equipment that is in
      Protected premises with limited access.
    </p>
    <p>
      Entering its personal data on the registration page and clicking "Register", the
      user also confirms that Notified about the inclusion of information about it in the
      database of personal data With the above goal, about his right, defined by the Law
      of Ukraine "On the protection of personal data", as well as about the persons with
      Transmit to perform the above goal.
    </p>
  </div>
</template>
<style scoped>
.policy-wrapper {
  max-width: 1218px;
  padding: 20px 15px;
  margin: 0 auto;
  box-sizing: border-box;
}
.policy-title {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  padding: 40px;
}
.policy-wrapper p {
  padding: 10px;
  font-size: 20px;
  line-height: 30px;
}
.policy-wrapper b {
  font-weight: 600;
}
</style>
